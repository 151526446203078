import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import CigarBannerimg from "../../../images/uploads/cigarsnearme/cigar-main-bg.png"
import cigarAboutimg from "../../../images/uploads/cigarsnearme/cigar-about-img.png"
import cigarKnowledgebg from "../../../images/uploads/cigarsnearme/cigar-knowledge-bg.png"
import cigarBgmain from "../../../images/uploads/cigarsnearme/cigar-bg-main.png"
import cigarSolutionimg from "../../../images/uploads/cigarsnearme/cigar-solution-img.png"
import cigarSolutionimgDark from "../../../images/uploads/cigarsnearme/cigar-solution-img-dark.png"
import cigarAccurate from "../../../images/uploads/cigarsnearme/cigar-accurate.png"
import cigarSol2 from "../../../images/uploads/cigarsnearme/cigar-sol-2.png"
import cigarSol2Dark from "../../../images/uploads/cigarsnearme/cigar-sol-2-dark.png"
import cigarSolbg from "../../../images/uploads/cigarsnearme/cigar-sol-bg.png"
import CigarSteps from "../../../images/uploads/cigarsnearme/decodeCigar-steps.png"
// import CigarGlobalDark from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-global-signals-dark.png"
import CigarGlobalSignal from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-global-signals-map.png"

// Cigar slider 
import cigarSS1 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-1.png"
import cigarSS2 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-2.png"
import cigarSS3 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-3.png"
import cigarSS4 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-4.png"
import cigarSS5 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-5.png"
import cigarSS6 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-6.png"
import cigarSS7 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-7.png"
import cigarSS8 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-8.png"
import cigarSS9 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-9.png"
import cigarSS10 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-10.png"
import cigarSS11 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-11.png"
import cigarSS12 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-12.png"
import cigarSS13 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-13.png"
import cigarSS14 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-14.png"
import cigarSS15 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-15.png"
import cigarSS16 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-16.png"
import cigarSS17 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-17.png"
import cigarSS18 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-18.png"
import cigarSS19 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-19.png"
import cigarSS20 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-20.png"
import cigarSS21 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-21.png"
import cigarSS22 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-22.png"
import cigarSS23 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-23.png"
import cigarSS24 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-24.png"
import cigarSS25 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-25.png"
import cigarSS26 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-26.png"
import cigarSS27 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-27.png"
import cigarSS28 from "../../../images/uploads/cigarsnearme/decodeup-cigar-near-me-slider-ss-28.png"


import addcs1 from "../../../images/uploads/decodeup-additional-case-study-square-one-media.png"
import addcs2 from "../../../images/uploads/decodeup-additional-case-study-medication-tracker.png"

const CigarNearme = props => {

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "cigarsnearme"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Cigars store/club locator & Social Networking Platform
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Social Media for Cigar Enthusiasts
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  After thoughtful discussion and planning we set out to
                  transform a simple idea into many possibilities, designed to
                  serve the needs of a vibrant community.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Kirtlan & Karen
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      United States of America
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      UI/UX Design, Development, SEO, Maintenance and Support,
                      Brand strategy, Hosting
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Smarty, Angular.JS, Google Services and API, Wordpress
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Entertainment & Media
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Main Banner section */}
        <section className="cigar-main-banner">
          <div className="container mx-auto">
            <img
              className="w-full h-full object-cover"
              src={CigarBannerimg}
              alt="Cigar DecodeUp Technologies"
            />
          </div>
        </section>
        {/* About project section */}
        <section className="pt-60 lg:pt-25">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 xl:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                About The Project
              </h2>
            </div>
            <div className="pb-8 lg:pb-10 flex flex-wrap sm:flex-nowrap gap-0 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2 cigar-about-img">
                <img
                  className="mx-auto"
                  src={cigarAboutimg}
                  alt="Cigar Decodeup Technologies"
                />
              </div>
              <div className="md:pt-60 lg:pt-1-20 w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Social Site for Cigar Fans
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Cigars are a true collector's item and for this husband and
                  wife team they wanted to create the ultimate haven for cigar
                  fans everywhere, complete with blogs, news and data.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  During our initial consultation we discovered their ultimate
                  goal was slightly different from what they thought was needed,
                  so our team went to work researching the best features for
                  their goals.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We evaluated the biggest challenges facing the cigar community
                  and devised a group of features for the site that would solve
                  those dilemmas.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  After 5 years of steady work and innovation, we're proud to
                  help build one of the largest online cigar communities.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Knowledge section */}
        <section className="pt-6 md:pt-8 lg:pt-20 mb-60 md:mb-20 xl:mb-1-40 cigar-knowledge">
          <div className="container mx-auto">
            <div className="mb-6 md:mb-8 lg:mb-10 w-full max-w-8-80 mx-auto text-center">
              <h3 className="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                Knowledge is Power
              </h3>
              <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                For travelers, it can be difficult to locate a trusted cigar
                club with great service. Is it better to Google it or ask
                around? Cigars Near Me provides an easy solution. you can find
                local cigar info from any city or states including prices,
                brands, and current availability. Not to mention real-life data
                and reviews from cigar experts to help you make the best
                selections.
              </p>
            </div>
          </div>
          <div className="cigar-knowledge-img">
            <img
              className="w-full h-full mx-auto object-cover"
              src={cigarKnowledgebg}
              alt=" cigar DecodeUp Technologies"
            />
          </div>
        </section>
        {/* Challanges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                The Challenges
              </h2>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  When the team at Cigars Near Me contacted DecodeUp, their
                  desire was simple: a shiny new website. But the collecting
                  several kinds of data for this tool would pose a challenge. We
                  needed to present store locations, product information,
                  pricing and reviews all in the same place.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  It was a difficult task to aggregate the data and ensure that
                  anything collected from public sources was accurate and could
                  be verified before publishing on the site.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  One of the greatest obstacles in planning this kind of tool
                  was pioneering a new technology that didn't exist. Because
                  there was no precedent for the cigar community, we had to
                  apply foresight to the potential needs, challenges and
                  benefits of this site.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  To move forward, we crafted a list of the greatest challenges
                  we would have to tackle in order to create an impressive tool.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="pl-6 md:pl-8 lg:pl-9 mb-5 cigar-challenge-inner relative">
                  <h5 className="mb-1 lg:mb-3 relative text-h5 lg:text-h4 font-poppings-bold text-term-primary">
                    Random Data Sets
                  </h5>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The most important key for cigar fans everywhere is being
                    able to locate the nearest cigar store. Since most cigar
                    enthusiasts are active web users, the goal was to bring all
                    locations together in one place.
                  </p>
                </div>
                <div className="pl-6 md:pl-8 lg:pl-9 mb-5 cigar-challenge-inner relative">
                  <h5 className="mb-1 lg:mb-3 relative text-h5 lg:text-h4 font-poppings-bold text-term-primary">
                    Lack of Inventory
                  </h5>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    It's hard to know what a store carries, even if you visit
                    in-person. You can't always identify the best selection or
                    if their inventory is legitimate. We had to verify and
                    identify inventory info at hundreds of locations.
                  </p>
                </div>
                <div className="pl-6 md:pl-8 lg:pl-9 mb-5 cigar-challenge-inner relative">
                  <h5 className="mb-1 lg:mb-3 relative text-h5 lg:text-h4 font-poppings-bold text-term-primary">
                    Lack of Accuracy
                  </h5>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    It's not easy to know what flavors, sizes and prices are
                    available for any given cigar at a certain location.
                    Accessing this information was not easy and would be a
                    challenge to maintain.
                  </p>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Cigars Near Me wanted someone to help them solve these dilemmas, and we're glad they chose us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Challanges section BG */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 w-full cigar-challange-bg">
          <img
            className="w-full h-full object-cover mx-auto"
            src={cigarBgmain}
            alt="Cigar Decodeup Technologies"
          />
        </section>

        {/* Solutions section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                The Solutions
              </h2>
            </div>
            <div className="mb-60 md:mb-20 xl:mb-1-40 flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
              <div className="w-full sm:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The Moment We've Been Waiting For!
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Our meticulous step-by-step process always begins by asking
                  the important questions to identify what the project needs to
                  accomplish. This includes the following steps:
                </p>
                <ul className="mb-5 flex flex-col gap-3 lg:gap-5 list-none cigar-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 xl:ml-10 relative">Discovery</li>
                  <li className="ml-6 xl:ml-10 relative">Plan/Budget</li>
                  <li className="ml-6 xl:ml-10 relative">Design</li>
                  <li className="ml-6 xl:ml-10 relative">Develop</li>
                  <li className="ml-6 xl:ml-10 relative">
                    Marketing (if applicable)
                  </li>
                </ul>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  During Discovery, we become well acquainted with our clients'
                  goals and help them to articulate and discover every aspect of
                  their ambitions. It's absolutely necessary, or else we're
                  creating blindly.
                </p>
              </div>
              <div className="w-full sm:w-1/2">
                <img
                  className="cigar-cstm-app cigar-map-light mx-auto"
                  src={cigarSolutionimg}
                  alt="Cigar Decodeup Technologies"
                />
                <img
                  className="cigar-cstm-app cigar-map-dark mx-auto"
                  src={cigarSolutionimgDark}
                  alt="Cigar Decodeup Technologies"
                />
              </div>
            </div>
          </div>

          {/* solution Map section */}
          <div className=" relative cigar-sol-map-sec">
            <div className="container mx-auto">
              <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
                <div className="w-full sm:w-1/2 lg:order-2">
                  <h3 className="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Global Signals
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    We designed personalized algorithms to fit the needs of
                    cigar enthusiasts. This essentially created "towers" that
                    existed virtually throughout the world which then collected
                    data for us that we used to operate the tool (it's kind of
                    top secret stuff so that's all we can say!).
                  </p>
                  <ul className="mb-5 flex flex-col gap-3 lg:gap-5 list-none cigar-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                    <li className="ml-6 xl:ml-10 relative">
                      {" "}
                      Design a special algorithm to catch data? Check
                    </li>
                    <li className="ml-6 xl:ml-10 relative">
                      {" "}
                      Store info virtually? Check
                    </li>
                    <li className="ml-6 xl:ml-10 relative">
                      Update location data automatically? Check
                    </li>
                  </ul>
                </div>

                <div className="w-full sm:w-1/2 lg:order-1">
                  <img
                    className="cigar-cstm-app  mx-auto"
                    src={CigarGlobalSignal}
                    alt="Cigar Decodeup Technologies"
                  />
                  {/* <img
                    className="cigar-cstm-app  mx-auto cigar-map-dark"
                    src={CigarGlobalDark}
                    alt="Cigar Decodeup Technologies"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="py-10 cigar-map-main">
            <div className="container mx-auto">
              <div className="cigar-map-main-step relative z-10">
                <img
                  className="w-full h-full mx-aut"
                  src={CigarSteps}
                  alt="Cigar DecodeUp Technologies"
                />
              </div>
            </div>
          </div>

          <div className="container mx-auto">
            <div className="mt-60 md:mt-20 xl:mt-1-40 flex flex-wrap sm:flex-nowrap gap-8 lg:gap-16 items-center relative z-1">
              <div className="w-full sm:w-1/2">
                <img
                  className="cigar-accurate-img"
                  src={cigarAccurate}
                  alt=""
                />
              </div>
              <div className="w-full sm:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Accurate Cigar Fact Aggregation
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  One of the greatest tasks to creating Cigars Near Me was
                  pulling together important information. This means collecting
                  information on the size, flavors, and brands of cigars.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  This data is organized by our algorithm and output into
                  easy-to-read information for users.
                </p>
                <ul className="mb-5 flex flex-col gap-3 lg:gap-5 list-none cigar-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 xl:ml-10 relative">
                    All data is collected from the web
                  </li>
                  <li className="ml-6 xl:ml-10 relative">
                    Algorithms filter each detail
                  </li>
                  <li className="ml-6 xl:ml-10 relative">
                    Data is presented on frontend to users
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-4 md:mt-8 xl:mt-16 flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center relative z-1">
              <div className="w-full sm:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Connecting the Dots
                </h3>
                <ul className="mb-5 flex flex-col gap-3 lg:gap-5 list-none cigar-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 xl:ml-10 relative">
                    The end product was a social networking site for cigar
                    enthusiasts, a place for users to share cigar information
                    and experiences on restaurants, bars, lounges and more.
                  </li>
                  <li className="ml-6 xl:ml-10 relative">
                    Users have the ability to message one another within the
                    site and engage in discussions about any number of cigar
                    topics.
                  </li>
                  <li className="ml-6 xl:ml-10 relative">
                    Listing for locations to find cigars around town are one of
                    the most popular features of the site. We use API to
                    coordinate store data and store owners claim their locations
                    and customize information about their shop.
                  </li>
                  <li className="ml-6 xl:ml-10 relative">
                    There are additional intuitive features such as reviewing
                    cigars, reviewing stores, connecting with other users,
                    saving posts, etc. It's a one-stop shop of cigar lovers!
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-1/2">
                <img
                  className="cigar-map-light"
                  src={cigarSol2}
                  alt="cigarSol2"
                />
                <img
                  className="cigar-map-dark"
                  src={cigarSol2Dark}
                  alt="cigarSol2"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Solution bg */}
        <section className="pt-16 mb-60 md:mb-20 xl:mb-1-40 w-full">
          <div className="container mx-auto">
            <img
              className="w-full h-full object-cover mx-auto"
              src={cigarSolbg}
              alt="Cigar Decodeup Technologies"
            />
          </div>
        </section>

        {/* typeface */}

        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="mb-6 md:mb-10">
              <div className="w-full">
                <h3 className="mb-4 md:mb-6 xl:mb-12 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & Colors
                </h3>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center cigar-typo ">
                <div className="w-full lg:w-1/2 cigar-typo-inner ">
                  <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h2 text-term-primary">
                    Roboto
                  </h3>
                  <h5 className="mb-1 text-term-primary opacity-70 text-p2">
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  </h5>
                  <h5 className="mb-1 text-term-primary opacity-70 text-p2">
                    abcdefghijklmnopqrstuvwxyz
                  </h5>
                  <h5 className="mb-1 text-term-primary opacity-70 text-p2">
                    1234567890
                  </h5>
                </div>

                <div className="w-full lg:w-1/2 flex flex-wrap">
                  <div className="w-1/2 lg:w-1/3">
                    <div className="cigar-color-plate">
                      <h4 className="text-white font-poppings-bold text-center text-h4">
                        #4B3025
                      </h4>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3">
                    <div className="cigar-color-plate cigar-color-plate-2">
                      <h4 className="text-white font-poppings-bold text-center text-h4">
                        #C3B59B
                      </h4>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3">
                    <div className="cigar-color-plate cigar-color-plate-3">
                      <h4 className="text-white font-poppings-bold text-center text-h4">
                        #FFFFFF
                      </h4>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3">
                    <div className="cigar-color-plate cigar-color-plate-4">
                      <h4 className="text-white font-poppings-bold text-center text-h4">
                        #E3D4BF
                      </h4>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3">
                    <div className="cigar-color-plate cigar-color-plate-5">
                      <h4 className="text-white font-poppings-bold text-center text-h4">
                        #7A4F3E
                      </h4>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3">
                    <div className="cigar-color-plate cigar-color-plate-6">
                      <h4 className="text-white font-poppings-bold text-center text-h4">
                        #224F64
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="w-full sm:w-2/3 lg:max-w-3xl">
              <h2 className="mb-4 lg:mb-8 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <h4 className="mb-4 text-h4 text-term-primary font-poppings-bold ">
                Clarity & Accessibility
              </h4>
              <ul className="mb-4 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal flex flex-col gap-3 cigar-result-inner">
                <li className="ml-6 xl:ml-10 relative">
                With an assortment of competition we knew Cigars Near Me had to accomplish two important things: clear information and easy access.
                </li>
                <li className="ml-6 xl:ml-10 relative">
                Every component was built for speed so users aren't met with long load times.
                </li>
                <li className="ml-6 xl:ml-10 relative">
                Our backend runs on multiple versions of API to seamlessly orchestrate large data sets.
                </li>
                <li className="ml-6 xl:ml-10 relative">
                Our team handpicked the best social networking features for an optimized experience.
                </li>
                <li className="ml-6 xl:ml-10 relative">
                Modern technology makes the site sleek, efficient and high-performing.
                </li>
                <li className="ml-6 xl:ml-10 relative">
                The final product was tested on multiple devices to ensure a positive experience on all devices.
                </li>
              </ul>
              <p className="mb-4 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-poppings-medium">
                Users quickly fell in love with Cigars Near Me because it's a
                simple solution to a problem that was complicated for way too
                long. They can easily find stores based on the cigars they sell,
                read reviews and connect with likeminded people. It's helped
                cigar enthusiasts to connect and learn about something they love
                at the click of a button. Store owners also benefited by
                reaching consumers in a central location, as the site provided
                an additional way to reach their target audience directly..
              </p>

              <div className="w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  It started with a simple idea to bring people together.
                  DecodeUp is proud to say we created a tool that accomplished
                  just that. Your dreams drive us everyday.
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS1}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS2}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS3}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS4}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS5}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS6}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS7}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS8}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS9}
                    alt="Cigar Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS10}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS11}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS12}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS13}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS14}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS15}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS16}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS17}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS18}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS19}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS20}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS21}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS22}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS23}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS24}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS25}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS26}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS27}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={cigarSS28}
                    alt="Pairdesi Decodeup Technologies"
                  />
                </Flickity>
              </div>
            </div>
          </div>
        </section>
        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>
        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/luxury-real-estatemedia-managements"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                  Luxury Real Estate Media Management System
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className="w-full h-full"
                      src={addcs1}
                      alt=" Square One Media Productions Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/medications-tracker"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Medication Tracker
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className="w-full h-full"
                      src={addcs2}
                      alt=" Medication Tracker Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default CigarNearme

export const pageQuery = graphql`
query cigarnearmepagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/social-media-for-cigar-enthusiasts.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}

`;